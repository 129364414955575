
  import { defineComponent, ref, watch } from "vue";
  import { Task } from "@/models/tasks"
  import apiClient from '@/api/apiClient'
  import { Column } from "@/models/table";
  import { getRefreshData } from "@/view_scripts/dashboardUtils";
  import { Data, Props, TileAction } from "@/views/shared/dashboard/dashboard.interface";;
  import { fullName, defaultPhone, defaultEmail, resourceUrl } from '@/utils/dataExtractors'

  export default defineComponent({
    props: {
      itemToCheck: {
        type: Object,
        default: () => ({})
      },
      modalTableItems: {
        type: Array,
        default: () => []
      },
      visible: {
        type: Boolean,
        default: true
      }
    },
    setup(props: any, { emit }) {
      const modalVisible = ref(props.visible)
      watch(() => props.visible, (newValue) => modalVisible.value = newValue, { immediate: true })

      const modalResolveAction = async (action: string) => {
        const url = resourceUrl(props.itemToCheck)
        await apiClient.patch(url, {usecase: action})
        emit('done', true)
      }

      const tableItems = [props.itemToCheck].concat(props.modalTableItems)

      const duplicateRow = (item: any, type: string) => {
        if (!item || type !== 'row') return
        if (item.id === props.itemToCheck.id) return 'highlighted_row'
      }

      const duplicateFields = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "attributes.address.attributes",
          title: "Name",
          label: "Name",
          sortable: true,
          options: {
            contentFunction: (data: any) => fullName(data.item.attributes.address),
            hrefFunction: (data: any) => resourceUrl(data.item)
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.protoLeadsource.attributes.name",
          label: 'Leadquelle',
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.address.attributes.phones",
          title: 'Telefon',
          label: 'Telefon',
          sortable: true,
          options: {
            contentFunction : (data: any) => {
              const add = data.item.attributes.address.attributes
              return defaultPhone(add).number
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.address.attributes.emails[0].email",
          title: 'E-Mail',
          label: 'E-Mail',
          sortable: true,
          options: {
            contentFunction: (data: any) => {
              const add = data.item.attributes.address.attributes
              return defaultEmail(add).email
            }
          }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "meta.crCount",
          label: '#EBs',
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.archived",
          label: 'Archiviert',
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.reclaimed",
          label: 'Reklamiert',
          sortable: true,
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.createdAt",
          label: 'Erstellt',
          sortable: true,
          options: { type: 'datetime' }
        },
        {
          component: () => import("@/components/shared/table/cells/ValueCell.vue"),
          key: "attributes.seller.attributes.last_name",
          label: 'Vertrieb',
          sortable: true,
          options: {
            contentFunction: (data:any) => {
              const seller = data.item.attributes.seller
              return fullName(seller)
            }
          }
        },
        // { key: 'phones', label: 'Telefon', sortable: true, formatter: (value: string[]) => value.join()},
        // { key: 'emails', label: 'E-Mail', sortable: true, formatter: (value: string[]) => value.join() },
      ]

      return {
        modalVisible,
        modalResolveAction,
        duplicateFields,
        tableItems,
        duplicateRow
      }
    }
  })
